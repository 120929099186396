<template>
  <div class="login page">
    <Nav></Nav>
    <main class="wrap">
      <div class="login-box-wrap">
        <div class="login-img">
          <img src="../assets/ship.png" />
        </div>
        <div class="login-box">
          <div class="login-logo">
            <a href="/"><img src="../assets/logo.png" /></a>
          </div>
          <a-tabs default-active-key="1" :tabBarStyle="{ 'text-align': 'center' }">
            <a-tab-pane key="1" tab="帐号" style="height:280px">
              <a-form id="components-form-demo-normal-login-pwd" :form="formpwd" class="login-form" @submit="pwdSubmit">
                <a-form-item>
                  <a-input v-decorator="[
                    'userName',
                    {
                      rules: [{ required: true, message: '帐号不能为空！' }],
                    },
                  ]" placeholder="帐号">
                    <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input v-decorator="[
                    'password',
                    { rules: [{ required: true, message: '请输入密码' }] },
                  ]" type="password" placeholder="密码">
                    <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <router-link class="login-form-forgot" to="/Forget"> 忘记密码</router-link>
                  <a-button type="primary" html-type="submit" class="login-form-button">
                    立即登录
                  </a-button>
                </a-form-item>
              </a-form>
            </a-tab-pane>
            <a-tab-pane key="2" tab="手机号" force-render>
              <a-form id="components-form-demo-normal-login-mobile" :form="formmobile" class="login-form"
                @submit="mobileSubmit">
                <a-form-item>
                  <a-row :gutter="8">
                    <a-col :span="15">
                      <a-input v-decorator="[
                        'mobile',
                        {
                          rules: [
                            { required: true, message: '手机号不能为空！' }
                            , {
                              pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,
                              message: '请输入正确的手机号',
                            }
                          ],
                        },
                      ]" placeholder="手机号">
                        <a-icon slot="prefix" type="mobile" style="color: rgba(0, 0, 0, 0.25)" />
                      </a-input>
                    </a-col>
                    <a-col :span="7">
                      <a-button @click="sendViewCode" :disabled="updateDisableFlag">
                        <span v-if="updateDisableFlag">({{ settimer }})秒后重发</span>
                        <span v-else>获取验证码</span>
                      </a-button>
                    </a-col>
                  </a-row>
                </a-form-item>
                <a-form-item>
                  <a-input v-decorator="[
                    'code',
                    { rules: [{ required: true, message: '请输入验证码' }] },
                  ]" placeholder="请输入收到的验证码">
                    <a-icon slot="prefix" type="book" style="color: rgba(0, 0, 0, 0.25)" />
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-button type="primary" html-type="submit" class="login-form-button">
                    立即登录
                  </a-button>
                </a-form-item>
              </a-form>
            </a-tab-pane>
          </a-tabs>
          <div class="u-flex">
            <div style="width: 100%;padding-left: 40px;">
              <a-button type="link" @click="wechatlogin">
                <a-icon type="wechat" style="font-size: 40px; color: #52c41a" />
              </a-button>
            </div>
            <div style="width:200px">
              <router-link to="/Register"> 注册帐号
                <a-icon type="arrow-right" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <Foot></Foot>
    </footer>
  </div>
</template>

<script>
import Foot from "@/components/foot.vue";
import Nav from "@/components/Nav.vue"

export default {
  components: {
    Nav,
    Foot,
  },
  data() {
    return {
      formpwd: this.$form.createForm(this, {
        name: "normal_login",
      }),
      formmobile: this.$form.createForm(this, {
        name: "mobile_mobile",
      }),
      updateDisableFlag: false,
      settimer: 60,
      shortId: 0,
    };
  },
  computed: {},
  methods: {
    pwdSubmit(e) {
      e.preventDefault();
      this.formpwd.validateFields((err, values) => {
        if (!err) {
          this.api
            .loginpw({
              user: values.userName,
              password: values.password,
            })
            .then((res) => {
              this.storage.setItem("token", res.access_token);
              this.storage.setItem("user", null);
              this.getUserInfo();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    mobileSubmit(e) {
      e.preventDefault();
      this.formmobile.validateFields((err, values) => {
        if (!err) {
          this.api
            .vercodeLogin({
              vid: this.shortId,
              mobile: values.mobile,
              code: values.code,
            })
            .then((res) => {
              this.storage.setItem("token", res.access_token);
              this.storage.setItem("user", null);
              this.getUserInfo();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    sendViewCode() {
      this.formmobile.validateFields(['mobile'], (err, values) => {
        if (!err) {
          //console.log(values);
          this.updateDisableFlag = true;
          let that = this;

          //发送短信方法
          this.api
            .sendVercode({
              mobile: values.mobile,
            })
            .then((res) => {
              this.shortId = res.id;
              this.$message.success("发送成功", 3);
              //计时开始
              let timer = setInterval(function () {
                that.updateDisableFlag = true
                that.settimer--;
                if (that.settimer == 0) {
                  clearInterval(timer)
                  that.updateDisableFlag = false
                  that.settimer = 60;
                }
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    wechatlogin() {
      let APPID = 'wxf5ca3451420f370c';
      let REDIRECT_URI = encodeURIComponent('http://www.diyunlife.com/#/');
      location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=' + APPID + '&redirect_uri=' + REDIRECT_URI + '&response_type=code&scope=snsapi_login&state=DY20230101#wechat_redirect';
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.user = res;
          this.storage.setItem("user", res);
          this.$router.push("/user");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeCreate() {

  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f5f5;
}

main {

  //position: relative;
  .login-box-wrap {
    width: 950px;
    height: 480px;
    background: #e8e6ed;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

    .login-box {
      background: #ffffff;
      width: 420px;
      height: 460px;
      border-radius: 10px;
      position: absolute;
      top: 10px;
      right: 20px;

      .login-logo {
        text-align: center;
        margin-top: 10px;
      }

      #components-form-demo-normal-login-pwd {
        margin: 40px auto;
        width: 70%;

        .login-form {
          max-width: 300px;
        }

        .login-form-forgot {
          float: right;
        }

        .login-form-button {
          width: 100%;
        }
      }

      #components-form-demo-normal-login-mobile {
        margin: 40px auto;
        width: 70%;

        .login-form {
          max-width: 300px;
        }

        .login-form-forgot {
          float: right;
        }

        .login-form-button {
          width: 100%;
        }
      }
    }

    .login-img {
      width: 480px;
      height: 440px;
      position: absolute;
      left: 26%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

footer {
  width: 100%;
  margin-top: 0;
  position: fixed;
  bottom: 0;
}
</style>