var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login page"},[_c('Nav'),_c('main',{staticClass:"wrap"},[_c('div',{staticClass:"login-box-wrap"},[_vm._m(0),_c('div',{staticClass:"login-box"},[_vm._m(1),_c('a-tabs',{attrs:{"default-active-key":"1","tabBarStyle":{ 'text-align': 'center' }}},[_c('a-tab-pane',{key:"1",staticStyle:{"height":"280px"},attrs:{"tab":"帐号"}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login-pwd","form":_vm.formpwd},on:{"submit":_vm.pwdSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'userName',
                  {
                    rules: [{ required: true, message: '帐号不能为空！' }],
                  } ]),expression:"[\n                  'userName',\n                  {\n                    rules: [{ required: true, message: '帐号不能为空！' }],\n                  },\n                ]"}],attrs:{"placeholder":"帐号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  { rules: [{ required: true, message: '请输入密码' }] } ]),expression:"[\n                  'password',\n                  { rules: [{ required: true, message: '请输入密码' }] },\n                ]"}],attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('router-link',{staticClass:"login-form-forgot",attrs:{"to":"/Forget"}},[_vm._v(" 忘记密码")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 立即登录 ")])],1)],1)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":"手机号","force-render":""}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login-mobile","form":_vm.formmobile},on:{"submit":_vm.mobileSubmit}},[_c('a-form-item',[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":15}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'mobile',
                      {
                        rules: [
                          { required: true, message: '手机号不能为空！' }
                          , {
                            pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,
                            message: '请输入正确的手机号',
                          }
                        ],
                      } ]),expression:"[\n                      'mobile',\n                      {\n                        rules: [\n                          { required: true, message: '手机号不能为空！' }\n                          , {\n                            pattern: /^1[3|4|5|7|8|9][0-9]\\d{8}$/,\n                            message: '请输入正确的手机号',\n                          }\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"手机号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mobile"},slot:"prefix"})],1)],1),_c('a-col',{attrs:{"span":7}},[_c('a-button',{attrs:{"disabled":_vm.updateDisableFlag},on:{"click":_vm.sendViewCode}},[(_vm.updateDisableFlag)?_c('span',[_vm._v("("+_vm._s(_vm.settimer)+")秒后重发")]):_c('span',[_vm._v("获取验证码")])])],1)],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code',
                  { rules: [{ required: true, message: '请输入验证码' }] } ]),expression:"[\n                  'code',\n                  { rules: [{ required: true, message: '请输入验证码' }] },\n                ]"}],attrs:{"placeholder":"请输入收到的验证码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"book"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 立即登录 ")])],1)],1)],1)],1),_c('div',{staticClass:"u-flex"},[_c('div',{staticStyle:{"width":"100%","padding-left":"40px"}},[_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.wechatlogin}},[_c('a-icon',{staticStyle:{"font-size":"40px","color":"#52c41a"},attrs:{"type":"wechat"}})],1)],1),_c('div',{staticStyle:{"width":"200px"}},[_c('router-link',{attrs:{"to":"/Register"}},[_vm._v(" 注册帐号 "),_c('a-icon',{attrs:{"type":"arrow-right"}})],1)],1)])],1)])]),_c('footer',[_c('Foot')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-img"},[_c('img',{attrs:{"src":require("../assets/ship.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../assets/logo.png")}})])])}]

export { render, staticRenderFns }